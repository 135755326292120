





















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import globalMixins from '@/mixins/global.ts'
import staffTable from '@/components/staffAccount/grid.vue'
import utils from "@/utils/util.ts"

@Component({
  components: {
    staffTable
  },
  mixins: [globalMixins]
})
export default class TempIndex extends Vue<globalMixins> {
  @Getter('wheelTotal') wheelTotal: number
  @Getter('wheelList') wheelList: Object[]
  @Getter("wheelSearchLists") wheelSearchLists: any[]
  @Action('wheelUsersList') getWheelLists: Function
  @Action('findUserToWheel') findUserToWheel: Function
  @Action('createWheelUser') createWheelUser: Function
  @Action('delWheelUser') delWheelUser: Function
  pagination: any = {
    page: 1,
    size: 10
  }
  isShowClose: boolean = false
  form: object = {
    id: 0,
    name: '',
    password: '',
    gender: '',
    email: '',
    organization: '',
    position: '',
    phone: ''
  }
  listLoading: boolean = false
  loading: boolean = false
  wheelIds: any[] = []
  currentPhone: string = ''
  account: any = JSON.parse(localStorage.getItem("account"))
  project = JSON.parse(localStorage.getItem("projects"))
  isPasShow: boolean = true
  dialogFormVisible: boolean = false
  formLabelWidth: string = '140px'
  formRules: object = {
    name: [
      { required: true, message: '请输入医生姓名', trigger: 'blur' }
    ],
    phone: [
      { required: true, message: '请选择手机号作为账号', trigger: 'blur' },
      {
        validator: (rule, value, callback) => {
          if (!/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(value)) {
            callback(new Error('请输入正确的手机号!'))
          } else {
            callback()
          }
        },
        trigger: ['blur', 'change'] 
      }
    ],
    gender: [
      { required: true, message: '请选择医生性别', trigger: 'change' }
    ],
    email: [
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
    ],
    password: [
      { min: 6, message: '密码长度至少为6位', trigger: ['blur', 'change'] }
    ]
  }
  created (){
    this.doSearch()
  }
  handleUserSelect () {
    // 匹配空格之后的电话号码
    let phone = (this.form as any).phone
    let currentInfo = (this.wheelSearchLists as any).find((item, i) => {
      return phone === item.phone
    })
    this.form = {
      ...currentInfo
    }
    this.isPasShow = false
  }
  // 根据手机号搜索用户info
  remoteMethod (query) {
    let params = {
      key: query
    }
    this.findUserToWheel({ params }).then(() => {
      if (!(this.wheelSearchLists && this.wheelSearchLists.length)) {
        (this.$refs['ruleForm'] as HTMLFormElement).resetFields()
        let form = (this.form as any)
        form.phone = query
        this.isPasShow = true
      }
      this.currentPhone = query
    })
  }
  handleUserBlur (query) {
    (this.form as any).phone = this.currentPhone
  }
  // 添加wheel用户
  usersSubmit () {
    let params = {
      ...this.form,
      is_wheel: true
    }
    let ruleForm = this.$refs.ruleForm as HTMLFormElement
    ruleForm.validate((valid: boolean) => {
      if (valid) {
        this.createWheelUser({ params }).then(() => {
          this.cancel()
          this.doSearch()
          this.successMsg('用户添加成功！')
        }).catch((err) => {
          this.errorMsg(err.error.message)
        })
      }
    })
  }
  cancel () {
    this.dialogFormVisible = false
  }
  doSearch () {
    this.listLoading = true
    let params = {
      is_wheel: 1
    }
    let pagination: any = utils.pager(this.pagination.page, this.pagination.size)
    params = Object.assign({}, params, pagination)
    this.getWheelLists({ params }).then(() => {
      this.listLoading = false
    }).catch((err) => {
      this.errorMsg(err.error.message)
      this.listLoading = false
    })
  }
  add () {
    this.dialogFormVisible = !this.dialogFormVisible
    this.$nextTick(() => {
      (this.$refs['ruleForm'] as any).resetFields()
    })
  }
  removeWheels (wheelIds) {
    this.wheelIds = wheelIds
  }
  remove () {
     if (this.wheelIds.length === 0) {
      this.warnMsg('请选择数据后再进行操作!')
      return
    }
    this.$confirm('此操作将删除所选用户, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      let params = {
        ids: this.wheelIds
      }
      this.delWheelUser({params}).then(() => {
        this.doSearch()
        this.successMsg('用户移除成功!')
      }).catch((err) => {
        this.errorMsg(err.error.message)
      })
    }).catch((err) => {
      this.errorMsg(err.error.message)
    })
  }
  onPagerSizeChange (val: string) {
    this.pagination.size = val
    this.doSearch()
  }
  onPagerCurrentChange (val: string) {
    this.pagination.page = val
    this.doSearch()
  }
}
