
































import { Component, Vue } from 'vue-property-decorator'

@Component({
  props: {
    wheelList: Array
  }
})

export default class ATSComponentsMerberManagers extends Vue {
  multipleSelection: any = []
  wheel_ids: any = []
  sexChange (type) {
    if (type === 'male') {
      return '男'
    } else {
      return '女'
    }
  }
  handleSelectionChange(val) {
    this.wheel_ids = []
    val.map((v: {id: number}) => {
      this.wheel_ids.push(v.id)
    })
    this.$emit('removeWheels', this.wheel_ids)
  }
}
